<!--
  描述：拖放地图组件，默认尺寸是 500 * 300
  接收属性参数：
    lat: 纬度
    lng: 经度
  自定义事件：
    drag: 拖放完成事件
  示例：
    <mapDrag @drag="dragMap" lat="22.574405" lng="114.095388"></mapDrag>
-->
<template>
  <div>
    <div class="m-map">
      <div class="search" v-if="placeSearch">
        <!-- <input
        type="text"
        placeholder="请输入关键字"
        v-model="searchKey"
      >
      <button
        type="button"
        @click="handleSearch"
      >搜索</button> -->
        <div id="js-result" v-show="searchKey" class="result"></div>
      </div>
      <div id="js-container" class="map">正在加载数据 ...</div>
    </div>
    <div id="status"></div>
    <div id="result"></div>
  </div>
</template>

<script>
import remoteLoad from '@/utils/remoteload.js';
import { MapKey } from '@/utils/config/config';
export default {
  //   props: ['lat', 'lng'],
  props: {
    lat: String,
    lng: String,
    MapCityName: {
      default: '广州市',
      type: String
    },
    positionArr: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      searchKey: '',
      placeSearch: null,
      dragStatus: false,
      AMapUI: null,
      AMap: null,
      cuname: ''
    };
  },
  watch: {
    searchKey() {
      if (this.searchKey === '') {
        this.placeSearch.clear();
      }
    },
    lat: {
      handler: function(value) {
        this.initMap();
      }
    },
    positionArr: {
      handler: function(value) {
        this.initMap();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 搜索
    handleSearch() {
      if (this.searchKey) {
        this.placeSearch.search(this.searchKey);
      }
    },
    // 实例化地图
    initMap() {
      // 加载PositionPicker，loadUI的路径参数为模块名中 'ui/' 之后的部分
      let AMapUI = (this.AMapUI = window.AMapUI);
      let AMap = (this.AMap = window.AMap);
      AMapUI.loadUI(['misc/PositionPicker'], PositionPicker => {
        let mapConfig = {
          zoom: 6,
          cityName: this.MapCityName
        };
        if (this.lat && this.lng) {
          mapConfig.center = [this.lng, this.lat];
        }
        let map = new AMap.Map('js-container', mapConfig);
        // 加载地图搜索插件
        // AMap.service("AMap.PlaceSearch", () => {
        //   this.placeSearch = new AMap.PlaceSearch({
        //     pageSize: 5,
        //     pageIndex: 1,
        //     citylimit: true,
        //     city: this.MapCityName,
        //     map: map,
        //     panel: "js-result",
        //   });
        // });
        //解析定位结果
        function onComplete(data) {
          //   document.getElementById("status").innerHTML = "定位成功";
          var str = [];
          str.push('定位结果：' + data.position);
          str.push('定位类别：' + data.location_type);
          if (data.accuracy) {
            str.push('精度：' + data.accuracy + ' 米');
          } //如为IP精确定位结果则没有精度信息
          str.push('是否经过偏移：' + (data.isConverted ? '是' : '否'));
          //   document.getElementById("result").innerHTML = str.join("<br>");
        }
        if (!this.lat || !this.lng) {
          let that = this;
          if (this.$store.state.current_project.organization.district_name) {
            AMap.plugin('AMap.Geolocation', function() {
              var geolocation = new AMap.Geolocation({
                enableHighAccuracy: true, //是否使用高精度定位，默认:true
                timeout: 10000, //超过10秒后停止定位，默认：5s
                buttonPosition: 'RB', //定位按钮的停靠位置
                buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
                zoomToAccuracy: true, //定位成功后是否自动调整地图视野到定位点
                panToLocation: false //定位成功后将定位到的位置作为地图中心点，默认：true
              });
              map.addControl(geolocation);
              geolocation.getCurrentPosition(function(status, result) {
                if (status == 'complete') {
                  onComplete(result);
                  map.setCity(
                    that.$store.state.current_project.organization.district_name
                  );
                } else {
                  // onError(result);
                }
              });
            });
          } else {
            AMap.plugin('AMap.Geolocation', function() {
              var geolocation = new AMap.Geolocation({
                enableHighAccuracy: true, //是否使用高精度定位，默认:true
                timeout: 10000, //超过10秒后停止定位，默认：5s
                buttonPosition: 'RB', //定位按钮的停靠位置
                buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
                zoomToAccuracy: true //定位成功后是否自动调整地图视野到定位点
              });
              map.addControl(geolocation);
              geolocation.getCurrentPosition(function(status, result) {
                if (status == 'complete') {
                  onComplete(result);
                } else {
                  // onError(result);
                }
              });
            });
          }
        } else {
          let that = this;
          AMap.plugin('AMap.Geolocation', function() {
            var geolocation = new AMap.Geolocation({
              enableHighAccuracy: true, //是否使用高精度定位，默认:true
              timeout: 10000, //超过10秒后停止定位，默认：5s
              buttonPosition: 'RB', //定位按钮的停靠位置
              buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
              zoomToAccuracy: false, //定位成功后是否自动调整地图视野到定位点
              panToLocation: false //定位成功后将定位到的位置作为地图中心点，默认：true
            });
            map.addControl(geolocation);
            geolocation.getCurrentPosition(function(status, result) {
              if (status == 'complete') {
                onComplete(result);
                map.setCenter([that.lng, that.lat]);
              } else {
                // onError(result);
              }
            });
          });
        }
        if (this.positionArr.length > 0) {
          this.positionArr.forEach(item => {
            let marker = new AMap.Marker({
              position: new AMap.LngLat(item.lng, item.lat),
              offset: new AMap.Pixel(-10, -10),
              content:
                "<div style='position: relative'><div class='mapIconBgView' style='background-image:url(" +
                (item.avatar
                  ? item.avatar
                  : 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png') +
                ");background-position:center;background-size:cover;background-repeat: no-repeat;background-color: #666;'></div> <div id=" +
                item.name +
                " class='itemInfo'><div class='sanjiao' ></div><div ><div class='name'>" +
                item.name +
                "</div><div class='time'>" +
                item.time +
                "</div></div><div class='site'>" +
                item.site +
                '</div></div></div>' // 添加 Icon 图标 URL
            });
            let that = this;
            marker.on('click', function(e) {
              if (that.cuname === item.name) {
                if (!document.getElementById(that.cuname).style.display) {
                  document.getElementById(that.cuname).style.display = 'block';
                } else {
                  document.getElementById(that.cuname).style.display =
                    document.getElementById(that.cuname).style.display ===
                    'none'
                      ? 'block'
                      : 'none';
                }
              } else {
                if (that.cuname) {
                  document.getElementById(that.cuname).style.display = 'none';
                }
                that.cuname = item.name;
                document.getElementById(that.cuname).style.display = 'block';
              }
              that.$emit('post', item.index);
            });
            map.add(marker);
          });
        }
        // 创建地图拖拽
        let positionPicker = new PositionPicker({
          mode: 'dragMap', // 设定为拖拽地图模式，可选'dragMap'、'dragMarker'，默认为'dragMap'
          map: map // 依赖地图对象
        });
        // 拖拽完成发送自定义 drag 事件
        positionPicker.on('success', positionResult => {
          // 过滤掉初始化地图后的第一次默认拖放
          if (!this.dragStatus) {
            this.dragStatus = true;
          } else {
            this.$emit('drag', positionResult);
          }
        });
        // 启动拖放
        positionPicker.start();
      });
    }
  },
  async created() {
    // 已载入高德地图API，则直接初始化地图
    if (window.AMap && window.AMapUI) {
      this.initMap();
      // 未载入高德地图API，则先载入API再初始化
    } else {
      await remoteLoad(`http://webapi.amap.com/maps?v=1.3&key=${MapKey}`);
      await remoteLoad('http://webapi.amap.com/ui/1.0/main.js');
      this.initMap();
    }
  },
  mounted(){
  }
};
</script>

<style lang="scss" scope>
.m-map {
  min-width: 500px;
  min-height: 300px;
  position: relative;
}
.m-map .map {
  width: 1168px;
  height: 395px;
}
.m-map .search {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 285px;
  z-index: 1;
}
.m-map .search input {
  width: 180px;
  border: 1px solid #ccc;
  line-height: 20px;
  padding: 5px;
  outline: none;
}
.m-map .search button {
  line-height: 26px;
  background: #fff;
  border: 1px solid #ccc;
  width: 50px;
  text-align: center;
}
.m-map .result {
  max-height: 300px;
  overflow: auto;
  margin-top: 10px;
}
.mapIconBgView {
  width: 40px;
  height: 40px;
  border: 1px solid white;
  justify-content: center;
  display: flex;
  border-radius: 50px;
  overflow: hidden;
}
.amap-ui-misc-positionpicker {
  display: none !important;
}
.itemInfo {
  width: 200px;
  height: 80px;
  position: absolute;
  top: -20px;
  left: 50px;
  display: none;
  background-color: #fefefe;
  /* background-color: red; */
  border-radius: 10px;
  div {
    display: flex;
    .name {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      height: 50%;
      white-space: nowrap;
      width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 46px;
      margin-left: 20px;
      margin-right: 20px;
    }
    .time {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      height: 50%;
      white-space: nowrap;
      width: 50px;
      margin-right: 30px;
      line-height: 46px;
      margin-left: 20px;
    }
  }
  .site {
    margin-left: 20px;
  }
}
.sanjiao {
  position: relative;
  width: 0;
  height: 0;
  border-width: 10px 10px 10px 10px;
  border-style: solid;
  border-color: transparent #fefefe transparent transparent;
  position: absolute;
  left: -20px;
  top: 30px;
}
.amap-copyright,
.amap-logo {
  display: none !important;
}
</style>
