<template>
  <div class="hongPage">
    <div class="goodMorning">
      <div class="left">
        <div class="head">
          <el-avatar icon="el-icon-user-solid" :size="80" :src="this.$store.state.user_info.avatar" v-if="this.$store.state.user_info.avatar" @error="errorHandler">
            <img :src="circleUrl" />
          </el-avatar>
          <el-avatar :size="80" icon="el-icon-user-solid" :src="circleUrl" v-else></el-avatar>
        </div>
        <div class="info">
          <div class="name">您好，{{ this.$store.state.user_info.name }}</div>
          <div class="deadline" v-if="this.$store.state.current_project.organization.service_level!=0">
            项目有效期至
            {{
              (this.$store.state.current_project.organization.service_expire *
                1000)
                | dateformat("YYYY-MM-DD")
            }}
          </div>
        </div>
      </div>
      <div class="right">
        <div class="item">
          <div class="top">
            <div class="title">巡检次数</div>
            <div class="par">较昨日</div>
            <div class="num" :class="+diffValue.patrolNum >= 0 ? 'colorbl' : 'colored'">
              {{ diffValue.patrolNum }}
            </div>
          </div>
          <div class="bottom colorbl">
            {{ todayData.patrol_count }}
          </div>
        </div>
        <div class="boright"></div>
        <div class="item">
          <div class="top">
            <div class="title">漏检次数</div>
            <div class="par">较昨日</div>
            <div class="num " :class="+diffValue.omitNum >= 0 ? 'colorbl' : 'colored'">
              {{ diffValue.omitNum }}
            </div>
          </div>
          <div class="bottom colored" v-if="todayData.prescribed_count - todayData.prescribed_patrol_count">
            {{ todayData.prescribed_count - todayData.prescribed_patrol_count }}
          </div>
        </div>
        <div class="boright"></div>
        <div class="item">
          <div class="top">
            <div class="title">任务完成数</div>
            <div class="par">较昨日</div>
            <div class="num " :class="
                +diffValue.completetaskcount_num >= 0 ? 'colorbl' : 'colored'
              ">
              {{ diffValue.completetaskcount_num }}
            </div>
          </div>
          <div class="bottom colorbl">
            {{ todayData.complete_task_count }}/{{ todayData.task_count }}
          </div>
        </div>
        <div class="item">
          <div class="top">
            <div class="title">已巡点位</div>
            <div class="par">较昨日</div>
            <div class="num" :class="+diffValue.patrol_count_num >= 0 ? 'colorbl' : 'colored'">
              {{ diffValue.patrol_count_num }}
            </div>
          </div>
          <div class="bottom colorbl">
            {{ todayData.cover_site_count }}/{{todayData.site_count}}
          </div>
        </div>
        <div class="boright"></div>
        <div class="item">
          <div class="top">
            <div class="title">发现异常</div>
            <div class="par">较昨日</div>
            <div class="num" :class="+diffValue.problem_count_num >= 0 ? 'colorbl' : 'colored'">
              {{ diffValue.problem_count_num }}
            </div>
          </div>
          <div class="bottom colored">
            {{ todayData.problem_count }}
          </div>
        </div>
        <div class="boright"></div>
        <div class="item">
          <div class="top">
            <div class="title">处理工单</div>
            <div class="par">较昨日</div>
            <div class="num" :class="
                +diffValue.handled_problem_count_num >= 0
                  ? 'colorbl'
                  : 'colored'
              ">
              {{ diffValue.handled_problem_count_num }}
            </div>
          </div>
          <div class="bottom colorbl">
            {{ todayData.handled_problem_count }}
          </div>
        </div>
      </div>
    </div>
    <div class="status">
      <div class="left">
        <div class="title">
          <img src="@/assets/img/巡检.png" /> 最近巡检情况
        </div>
        <div v-if="this.memberLastPatrolList.length === 0" class="flex_center_col_100" style="height:100%">
          暂无数据
        </div>
        <el-carousel height="200px" direction="vertical" :autoplay="true" :loop="true" :interval="5000" v-if="this.memberLastPatrolList.length > 0" ref="carou">
          <el-carousel-item v-for="item in memberLastPatrolList" :key="item.user_id">
            <div class="people">
              <el-avatar icon="el-icon-user-solid" :size="28" :src="item.avatar" v-if="item.avatar" style="margin-right:16px" @error="errorHandler">
                <img :src="circleUrl" />
              </el-avatar>
              <el-avatar icon="el-icon-user-solid" :size="28" :src="circleUrl" v-else style="margin-right:16px"></el-avatar>
              <div class="name">{{ item.name }}</div>
              <div class="location">{{ item.last_patrol_site_name }}</div>
              <div class="time">
                {{ (item.last_patrol_stamp * 1000) | dateformat("HH:mm") }}
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="right">
        <div class="title"><img src="@/assets/img/奖杯.png" />今日之星</div>
        <div :class="top3List.length > 1 ? 'count' : 'count_c'" v-if="this.top3List.length > 0">
          <div class="first" v-if="top3List[0]" style="background-color: #e9eef3">
            <div class="relative">
              <img src="@/assets/img/第一名.png" v-if="top3List[0]" />
              <el-avatar icon="el-icon-user-solid" :size="28" :src="top3List[0].patrol_user_avatar" v-if="top3List[0].patrol_user_avatar" @error="errorHandler">
                <img :src="circleUrl" />
              </el-avatar>
              <el-avatar icon="el-icon-user-solid" :size="28" :src="circleUrl" v-else></el-avatar>
            </div>
            <div class="name">{{ top3List[0].patrol_user_name }}</div>
            <div class="counts">{{ top3List[0].count }}次</div>
          </div>
          <div class="second" v-if="top3List[1]" style="background-color: #e9eef3">
            <div class="relative">
              <img src="@/assets/img/第二名.png" />
              <el-avatar icon="el-icon-user-solid" :size="28" :src="top3List[1].patrol_user_avatar" v-if="top3List[1].patrol_user_avatar" @error="errorHandler">
                <img :src="circleUrl" />
              </el-avatar>
              <el-avatar icon="el-icon-user-solid" :size="28" :src="circleUrl" v-else></el-avatar>
            </div>
            <div class="name">{{ top3List[1].patrol_user_name }}</div>
            <div class="counts">{{ top3List[1].count }}次</div>
          </div>
          <div class="second" v-else></div>
          <div class="third" v-if="top3List[2]" style="background-color: #e9eef3">
            <div class="relative">
              <img src="@/assets/img/第三名.png" v-if="top3List[2]" />
              <el-avatar icon="el-icon-user-solid" :size="28" :src="top3List[2].patrol_user_avatar" v-if="top3List[2].patrol_user_avatar" @error="errorHandler">
                <img :src="circleUrl" />
              </el-avatar>
              <el-avatar icon="el-icon-user-solid" :size="28" :src="circleUrl" v-else></el-avatar>
            </div>
            <div class="name">{{ top3List[2].patrol_user_name }}</div>
            <div class="counts">{{ top3List[2].count }}次</div>
          </div>
          <div class="third" v-else></div>
        </div>
        <div v-if="this.top3List.length === 0" class="flex_center_col_100" style="height:40%">
          暂无数据
        </div>
      </div>
    </div>
    <div class="lastSite">
      <i class="el-icon-location-outline
"></i> 最近人员位置
    </div>
    <div style="background-color:grey;width:1168px;height:395px;position:relative;border-radius:5px">
<!--      <div class="mengban" v-if="this.$store.state.current_project.organization.service_level==0">地图功能,仅对付费版开放</div>-->
      <dragMaps :lat.sync="lat" :lng.sync="lng" :positionArr.sync="dataArr" @post="control" style="border-radius:5px;overflow:hidden"></dragMaps>
    </div>
  </div>
</template>

<script>
import { organization, statistic, patrol, user } from '../../utils/api';
import qs from 'qs';
import dragMaps from '../../components/dragMap';
export default {
  name:"homePage",
  data() {
    return {
      circleUrl:
        'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      dayReport: {
        date_stamp: new Date(new Date().toLocaleDateString()).getTime() / 1000
      },
      diffValue: {},
      memberLastPatrolList: [],
      todayData: {},
      top3Par: {
        scope: 2,
        period: 0,
        date_stamp: new Date(new Date().toLocaleDateString()).getTime() / 1000
      },
      top3List: [],
      lng: '', //平均的经纬度
      lat: '',
      dataArr: []
    };
  },

  components: {
    dragMaps
  },

  computed: {},
  created() {
    this.getReport();
    this.memberLastPatrol();
  },
  mounted() {
    this.getTop3();
    this.$nextTick(() => {
      if (this.$store.state.current_project.organization.service_level == 0) {
        console.log(document.querySelector('.amap-geo'))
        document.querySelector('.amap-geo').style.display = 'none';
        document.getElementsByClassName('amap-geo')[0].style.display = 'none';
        document.getElementsByClassName('amap-geolocation-con')[0].style.display = 'none';

      }
    });
  },
  methods: {
    async getReport() {
      let par = qs.stringify(this.dayReport);
      const res = await this.$http.get(
        statistic.organizationDailyReport + '?' + par
      );
      let todayData = res.data.today;
      let yesterdayData = res.data.yesterday;
      this.todayData = todayData;
      this.yesterdayData = yesterdayData;
      function addPlus(today, yesterday) {
        let result = today - yesterday;
        if (result >= 0) {
          result = '+' + result;
        }
        return result;
      }
      let diffValue = {
        //巡检次数
        patrolNum: addPlus(todayData.patrol_count, yesterdayData.patrol_count),
        //漏检次数
        omitNum: addPlus(
          todayData.prescribed_count - todayData.prescribed_patrol_count,
          yesterdayData.prescribed_count - yesterdayData.prescribed_patrol_count
        ),
        // 任务完成数
        completetaskcount_num: addPlus(
          todayData.complete_task_count,
          yesterdayData.complete_task_count
        ),
        //已巡点位
        patrol_count_num: addPlus(
          todayData.prescribed_patrol_count,
          yesterdayData.prescribed_patrol_count
        ),
        //发现异常
        problem_count_num: addPlus(
          todayData.problem_count,
          yesterdayData.problem_count
        ),
        // 处理工单
        handled_problem_count_num: addPlus(
          todayData.handled_problem_count,
          yesterdayData.handled_problem_count
        )
      };
      this.diffValue = diffValue;
    },
    async memberLastPatrol() {
      const res = await this.$http.get(patrol.memberLastPatrol);
      this.lng = '';
      this.lat = '';
      let lat = 0,
        lng = 0,
        num = 0;
      this.dataArr = [];
      res.data.forEach((item, index) => {
        if (item.last_patrol_latitude && item.last_patrol_longitude) {
          num++;
          lat = lat + (item.last_patrol_latitude - 0);
          lng = lng + (item.last_patrol_longitude - 0);
          let dataObj = {};
          dataObj.lat = item.last_patrol_latitude;
          dataObj.lng = item.last_patrol_longitude;
          dataObj.index = index;
          dataObj.name = item.name;
          if (item.avatar) {
            dataObj.avatar = item.avatar;
          } else {
            dataObj.avatar = this.circleUrl;
          }
          dataObj.time = this.$moment(item.last_patrol_stamp).format('hh:mm');
          dataObj.site = item.last_patrol_site_name;
          if (
            this.$store.state.current_project.organization.service_level !== 0
          ) {
            this.dataArr.push(dataObj);
          }
        }
      });
      this.memberLastPatrolList = res.data;
      if (num === 0) {
        return false;
      } else {
        this.lat = lat / num + '';
        this.lng = lng / num + '';
      }
    },
    async getTop3() {
      const res = await this.$http.get(
        statistic.patrolTop3 + '?' + qs.stringify(this.top3Par)
      );
      this.top3List = res.data;
    },
    control(e) {
      this.$refs.carou.setActiveItem(e);
    },
    errorHandler() {
      return true;
    }
  },
  watch: {
    '$store.state.projectValue'(value) {
      this.getReport();
      this.memberLastPatrol();
      this.getTop3();
    }
  }
};
</script>
<style lang="scss" scoped>
.hongPage {
  width: 1200px;
  padding: 16px;
  min-height: 100px;
  padding-top: 0;
  // background-color: #fff;
  .goodMorning {
    display: flex;
    align-items: center;
    width: 1168px;
    height: 176px;
    background-color: #fff;
    border-radius: 5px;
    .left {
      box-sizing: border-box;
      width: 50%;
      height: 100%;
      // background-color: #541351;
      display: flex;
      align-items: center;
      .head {
        margin: 0 24px 0 32px;
      }
      .info {
        height: 80px;
        .name {
          font-size: 36px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 50px;
          width: 440px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .deadline {
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 27px;
        }
      }
    }
    .right {
      height: 100%;
      // background-color: #156413;
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      margin-right: 14px;
      .item {
        width: 33.2%;
        height: 50%;
        // background-color: aqua;
        .top {
          display: flex;
          width: 100%;
          justify-content: space-around;
          padding-top: 24px;
          .title {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 700;
            color: #333333;
            line-height: 20px;
            height: 50%;
            width: 64px;
            white-space: nowrap;
          }
          .par {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
            // margin-left: 40px;
          }
          .num {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 20px;
            margin-left: -25px;
          }
          .colored {
            color: #f56c6c;
          }
          .colorbl {
            color: #0486fe;
          }
        }
        .bottom {
          display: flex;
          align-items: center;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 700;
          color: #333333;
          height: 50%;
          line-height: 28px;
          padding-left: 18px;
        }
      }
      .boright {
        height: 34px;
        border-right: 1px dashed #f4f4f4;
        margin-top: 27px;
      }
    }
  }
  .status {
    width: 1168px;
    height: 113px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .left {
      box-sizing: border-box;
      width: 579px;
      height: 100%;
      padding: 16px;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      border-radius: 5px;
      .title {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 20px;

        img {
          width: 14px;
          height: 16px;
          margin-right: 8px;
        }
      }
      .people {
        margin-top: 24px;
        display: flex;
        align-items: center;
        el-avatar {
          margin-right: 16px;
          width: 28px;
          height: 28px;
        }
        .name {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 14px;
          margin-right: 16px;
        }
        .time {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 14px;
          margin-left: 20px;
        }
      }
    }
    .right {
      box-sizing: border-box;
      width: 579px;
      height: 100%;
      background-color: #fff;
      padding: 16px;
      padding-right: 0px;
      border-radius: 5px;
      .title {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 20px;
        margin-bottom: 7px;
        img {
          width: 14px;
          height: 16px;
          margin-right: 8px;
        }
      }
      .count {
        display: flex;
        justify-content: space-between;
      }
      .count_c {
        display: flex;
        justify-content: center;
      }
      .first {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 61px;
        border-radius: 10px;
        padding-left: 10px;
        box-sizing: border-box;
        padding-top: 15px;
        margin-right: 10px;
      }
      .second {
        flex: 1;
        height: 61px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        box-sizing: border-box;
        padding-top: 15px;
        padding-left: 10px;
        margin-right: 10px;
      }
      .third {
        flex: 1;
        height: 61px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        box-sizing: border-box;
        padding-top: 15px;
        padding-left: 10px;
        margin-right: 10px;
      }
      .relative {
        position: relative;
        img {
          position: absolute;
          top: -14px;
          left: 50%;
          margin-left: -10px;
        }
      }
      .name {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 14px;
        width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .counts {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 14px;
        margin-right: 20px;
      }
    }
  }
  .connect {
    width: 60px;
    // height: 120px;
    // background-color: brown;
    position: fixed;
    right: 1%;
    bottom: 20%;
    // background-color: #fff;
    // font-size: 0;
    img {
      width: 100%;
      height: 50%;
      margin: 2px 0;
      border: 1px solid #eeeeee;
      border-radius: 10px;
      background-color: #fff;
      padding: 5px;
      &:hover .mini {
        display: block;
      }
    }
    .mini {
      width: 200px;
      height: 200px;
      position: absolute;
      left: -211px;
      top: 70px;
      display: none;
    }
    .phone {
      display: inline-block;
      width: 200px;
      height: 58px;
      background-color: #ffffff;
      position: absolute;
      left: -212px;
      font-size: 28px;
      font-weight: 700;
      text-align: center;
      line-height: 58px;
      top: 4px;
      border-radius: 10px;
      display: none;
    }
  }
  .lastSite {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 700;
    color: #333333;
    line-height: 40px;
    margin-left: 10px;
  }
  .relative:hover .mini,
  .relative:hover .phone {
    display: block;
  }
  .colored {
    color: #f56c6c !important;
  }
  .colorbl {
    color: #0486fe !important;
  }
}
.amap-copyright,
.amap-logo {
  display: none;
}
.amap-maps,
.amap-e,
.amap-layers,
.amap-tile,
.amap-tile-container {
  border-radius: 5px;
}
.mengban {
  width: 1168px;
  height: 395px;
  position: absolute;
  z-index: 100;
  background-color: rgba(141, 141, 141, 0.7);
  line-height: 395px;
  font-weight: 600;
  font-size: 40px;
  text-align: center;
}
</style>
